import { OnImageJobUpdate } from '@/graphql/generated'
import { filter, isNull } from 'lodash'
import { acceptHMRUpdate, defineStore } from 'pinia'

export type ImageJob = NonNullable<NonNullable<OnImageJobUpdate['onImageJobUpdate']>>

export const useImageJobStore = defineStore('imageJob', {
  state: () => {
    const jobs = ref<ImageJob[]>([])
    const setJobs = (value: ImageJob[]): void => {
      jobs.value = filter(value, (job) => !isNull(job))
    }

    return {
      jobs,
      setJobs,
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useImageJobStore, import.meta.hot))
}
