<script setup lang="ts">
import UiLoader from '@c/Ui/Loader.vue'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { mdiGoogle } from '@mdi/js'
import GoogleIcon from '@/assets/icons/google.svg'
import { GetUserProfile, GetUserProfileVariables } from '@/graphql/generated'

const PROVIDER = 'google' as const
const GOOGLE_REDIRECT_URI = `${window.location.origin}/auth/google` as const
// const GOOGLE_REDIRECT_URI = `${window.location.href}` as const
const router = useRouter()
const route = useRoute()
const googleOauth2Code = computed(() => route.query.code?.toString())
const isRedirectFromGoogle = computed(() => route.params.provider.toString() === PROVIDER)

const goToGoogleLogin = () => {
  const oauthBase = `${import.meta.env.VITE_CONGNITO_OAUTH2_URL}/authorize?identity_provider=Google&client_id=${import.meta.env.VITE_CONGNITO_CLIENT_ID}&response_type=code&scope=email+openid`
  const oauthUrl = `${oauthBase}&redirect_uri=${encodeURIComponent(GOOGLE_REDIRECT_URI)}`
  window.location.href = oauthUrl
}

const { client } = useApiClient()
const { t } = useI18n()
const sessionStore = useSessionStore()
const uiStore = useUiStore()

const loading = ref(false)
const { executeMutation: setUserProfile } = useSetUserProfile()
const getGoogleToken = async (code: string) => {
  const url = `${import.meta.env.VITE_CONGNITO_OAUTH2_URL}/token`
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  const body = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: import.meta.env.VITE_CONGNITO_CLIENT_ID,
    redirect_uri: GOOGLE_REDIRECT_URI,
    code,
  })

  const res = await fetch(url, { method: 'POST', headers: headers, body: body })
  const data = (await res.json()) as {
    id_token: string
    access_token: string
    refresh_token: string
    expires_in: number
    token_type: string
  }
  // const profileResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
  //   headers: {
  //     Authorization: `Bearer ${data.access_token}`,
  //   },
  // })

  return data
}
/**
 * Only run when redirect from google and have query code
 */
const getGoogleTokenAndSigInToCognito = async () => {
  if (!googleOauth2Code.value || !isRedirectFromGoogle.value) return

  loading.value = true
  const token = await getGoogleToken(googleOauth2Code.value)
  const session = createCognitoSessionFromJWT({
    idTokenJWT: token.id_token,
    accessTokenJWT: token.access_token,
    refreshTokenJWT: token.refresh_token,
  })
  if (!session.isValid()) {
    // router.replace('/auth/login')
    uiStore.openLoginDialog = true
    uiStore.addToast({ type: 'error', message: t('auth.errors.expired') })
    loading.value = false

    return
  }

  try {
    const cognitoUser = getCognitoUser(session.getAccessToken().decodePayload().username)
    cognitoUser.setSignInUserSession(session)
    localStorage.setItem('Starpx.cognito.provider', PROVIDER)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('[getGoogleTokenAndSigInToCognito]', { e })
  }

  const tokens = getJWTfromCognitoSession(session)
  sessionStore.setJWTTokens(tokens, PROVIDER)

  const { data: profileData } = await client.value.query<GetUserProfile, GetUserProfileVariables>(
    GetUserProfileDocument,
    {},
  )
  if (!profileData?.getUserProfile) {
    sessionStore.logout({
      notify: false,
      callback: () => {
        uiStore.openLoginDialog = true
        // router.replace('/auth/login')
        uiStore.addToast({ type: 'error', message: t('Can not login with google') })
        loading.value = false
      },
    })

    return
  }

  const redirectUrl = route.query.redirect?.toString()
    ? route.query.redirect?.toString()
    : '/gallery'
  router.replace(redirectUrl)
  uiStore.addToast({
    type: 'success',
    message: t('auth.oAuth.dialog.success', { provider: PROVIDER }),
  })
  sessionStore.setUser(profileData.getUserProfile)
  sessionStore.setUserViaGoogle(profileData.getUserProfile)
  loading.value = false
}

getGoogleTokenAndSigInToCognito()
</script>

<template>
  <div
    @click="goToGoogleLogin"
    class="flex cursor-pointer flex-row items-center justify-between rounded-md border border-gray-600 px-3 py-2"
  >
    <div class="flex flex-row items-center gap-2">
      <i class="icon-icon_colored h-6 w-6 bg-blue-600"></i>
      <div v-if="sessionStore.userViaGoogle?.email" class="flex flex-col">
        <div class="text-sm">Continue as {{ sessionStore.userViaGoogle?.fullname }}</div>
        <div class="text-sm text-gray-400">{{ sessionStore.userViaGoogle?.email }}</div>
      </div>
      <div v-else>
        <div class="text-md">Continue with google</div>
      </div>
    </div>
    <img :src="GoogleIcon" class="h-8 w-8" />
  </div>

  <UiLoader v-if="loading" class="z-10" theme="overlay" />
</template>
