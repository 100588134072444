<template>
  <Suspense>
    <LayoutDefault>
      <RouterView v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <!-- <keep-alive include="Gallery.page"> -->
          <component :is="Component" :key="$route.fullPath" />
          <!-- </keep-alive> -->
        </transition>
      </RouterView>
      <AcceptCookies v-if="!accepted.value && !selected.value" />
      <UploadWindow v-if="isVisibleUploadWindow" />
      <Selector />
      <SetupUserDialog
        v-if="showSetupDialog"
        @close="handleSetupComplete"
        @setup-complete="handleSetupComplete"
      />
    </LayoutDefault>
  </Suspense>
</template>

<script setup lang="ts">
import LayoutDefault from '@c/Layout/Layout.vue'
import AcceptCookies from '@c/Service/AcceptCookies.vue'
import Selector from '@c/Uploader/Selector.vue'
import UploadWindow from '@c/Uploader/Window.vue'
import { useEventListener, whenever } from '@vueuse/core'
import SetupUserDialog from '@/components/Setup/SetupUserDialog.vue'

useApiClientProvider()

const sessionStore = useSessionStore()
const uiStore = useUiStore()
const uploadStore = useUploadStoreV2()
const { isVisibleUploadWindow, hasUploadingOrQueue } = storeToRefs(uploadStore)

const accepted = computed(() => ref(sessionStore.acceptCookies))
const selected = computed(() => ref(sessionStore.declineCookies))

const log = useLogger()
const showSetupDialog = ref(false)

// Get user profile to check last_login
const { user } = storeToRefs(sessionStore)

// Show setup dialog if user has no last_login
whenever(user, () => {
  if (!user.value?.last_login) {
    showSetupDialog.value = true
  }
})

const handleSetupComplete = () => {
  showSetupDialog.value = false
}

log.log({ env: import.meta.env })

onMounted(() => {
  if (!uiStore.theme || uiStore.theme === 'dark') {
    document.documentElement.classList.add('dark')
    uiStore.setTheme('dark')
  } else if (
    !('Starpx.theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  const handleBeforeUnload = (event: Event) => {
    if (!hasUploadingOrQueue.value) return
    event.preventDefault()
    event.returnValue = true // Standard for displaying an alert on reload
  }

  // Add event listener to prompt alert on page reload
  window.addEventListener('beforeunload', handleBeforeUnload)

  // Clean up the event listener when component is unmounted
  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload)
  })
})
uploadStore.init()
whenever(hasUploadingOrQueue, () => {
  uploadStore.setIsVisibleUploadWindow(true)
})
useEventListener(window, 'beforeunload', (e) => {
  if (hasUploadingOrQueue.value) {
    e.preventDefault()
  }
})

const isProd = import.meta.env.PROD
if (isProd) {
  useHead({
    script: [
      {
        src: 'https://www.googletagmanager.com/gtag/js?id=G-DLDRT50ZMY',
        async: true,
      },
      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());
          gtag("config", "G-DLDRT50ZMY");

          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted',
            'functionality_storage': 'granted',
            'personalization_storage': 'granted',
            'security_storage': 'granted',
            'wait_for_update': 500
          });
        `,
      },
    ],
  })
}

useGallerySubscriptionJobUpdate()
useGallerySubscriptionImageSetUpdate()
useGallerySubscriptionJobUpdate()
useImageChannelUpdateSubscription()
</script>
