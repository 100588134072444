<template>
  <footer
    v-if="galleryStore.isFinishMore || !isGallery"
    :class="[
      {
        'bottom-0 z-[9998] w-[cacl(100vw_-_20px)] bg-white text-gray-900 dark:bg-gray-900 dark:text-white':
          isGallery,
        'fixed bottom-0 w-full bg-transparent backdrop-blur-lg': route.path === '/',
        'bg-white text-gray-900 dark:bg-gray-900 dark:text-white': route.path !== '/',
      },
      'footer-section mt-auto h-20 py-2',
    ]"
    ref="footerRef"
  >
    <div class="container">
      <div class="space-x-4 py-4 md:flex md:items-center md:justify-between">
        <div class="flex items-center justify-center space-x-2 md:order-1 md:mt-0">
          <i class="icon-icon_white h-8 w-8 bg-blue-500"></i>
          <p class="text-center text-sm text-gray-500 dark:text-gray-200">
            &copy; {{ currentYear }} starpx, Inc.
          </p>
        </div>
        <div class="flex justify-center md:order-2">
          <RouterLink
            v-for="link in links"
            :key="link.href"
            :to="link.href"
            class="px-2 text-sm text-gray-500 transition hover:text-blue-900 dark:text-gray-200 dark:hover:text-blue-300"
          >
            {{ $t(link.label) }}
          </RouterLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router'
import { useHomepageStore } from '@/core/store/homepageStore'

const homepageStore = useHomepageStore()
const { titleScreen } = storeToRefs(homepageStore)

const ui = useUiStore()

const links = [
  {
    href: '/terms-of-service',
    label: 'footer.menu.terms',
  },
  {
    href: '/privacy-policy',
    label: 'footer.menu.privacy',
  },
  {
    href: '/',
    label: 'footer.menu.contacts',
  },
]

const galleryStore = useGalleryStore()
const currentYear = new Date().getFullYear()
const route = useRoute()
const isGallery = computed(() => route.path.includes('gallery'))

const footerRef = ref<HTMLElement | null>(null)
onMounted(() => {
  ui.setFooterHeight(Number(footerRef.value?.offsetHeight))
})
</script>
