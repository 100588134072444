import { defineStore, acceptHMRUpdate } from 'pinia'

export const useHomepageStore = defineStore('homepage', {
  state: () => {
    const currentPosition = ref<number>(0)

    const setCurrentPosition = (position: number) => {
      currentPosition.value =
        position < 0 ? 0 : position < screensCount.value ? position : screensCount.value - 1
    }

    const incrementCurrentPosition = () => {
      setCurrentPosition(currentPosition.value + 1)
    }

    const decrementCurrentPosition = () => {
      setCurrentPosition(currentPosition.value - 1)
    }

    const screensCount = ref<number>(0)

    const setScreensCount = (count: number) => {
      screensCount.value = count
    }

    const scrollTo = (counter: number) => {
      const scrollSnapBlocks = document.querySelectorAll('.snap-start')

      setCurrentPosition(counter)

      scrollSnapBlocks[currentPosition.value].scrollIntoView({ behavior: 'smooth' })
    }

    const titleScreen = ref<boolean>(false)
    const showTitleScreen = () => (titleScreen.value = true)
    const hideTitleScreen = () => (titleScreen.value = false)

    return {
      currentPosition,
      setCurrentPosition,
      incrementCurrentPosition,
      decrementCurrentPosition,
      screensCount,
      setScreensCount,
      scrollTo,
      titleScreen,
      showTitleScreen,
      hideTitleScreen,
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHomepageStore, import.meta.hot))
}
