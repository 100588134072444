import { nanoid } from 'nanoid'
import { IToast, IToastPayload } from '@/core/interface'
import { defineStore, acceptHMRUpdate } from 'pinia'

export const useUiStore = defineStore('ui', {
  state: () => {
    type ThemeType = 'white' | 'dark' | undefined

    const mobileMenuActive = ref(false)
    const toasts = ref<IToast[]>([])
    const theme = ref<ThemeType>('dark')

    const headerHeight = ref(0)
    const titleHeight = ref(0)
    const footerHeight = ref(0)

    const openLoginDialog = ref(false)
    const setMobileMenu = (isActive: boolean) => {
      mobileMenuActive.value = isActive
    }

    const addToast = (toast: IToastPayload) => {
      const id = nanoid()

      toasts.value.push({
        ...toast,
        id,
        timestamp: new Date().getTime(),
      })
    }

    const removeToast = (id: string) => {
      toasts.value = toasts.value.filter((t) => t.id !== id)
    }

    const setTheme = (newTheme: ThemeType) => {
      theme.value = newTheme
    }

    const setHeaderHeight = (value: number) => {
      headerHeight.value = value
    }

    const setFooterHeight = (value: number) => {
      footerHeight.value = value
    }

    const setTitleHeight = (value: number) => {
      titleHeight.value = value
    }

    return {
      toasts,
      mobileMenuActive,
      theme,
      setMobileMenu,
      addToast,
      removeToast,
      setTheme,
      openLoginDialog,
      headerHeight,
      setHeaderHeight,
      footerHeight,
      setFooterHeight,
      titleHeight,
      setTitleHeight,
    }
  },
  persist: [
    {
      paths: ['theme'],
      storage: localStorage,
    },
  ],
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUiStore, import.meta.hot))
}
