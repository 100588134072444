import { GetImageSetSummary2, OnImageChannelUpdate } from '@/graphql/generated'
import { SubscriptionHandlerArg } from '@urql/vue'
import { uniqBy } from 'lodash'

type Channels = NonNullable<GetImageSetSummary2['getImageSetSummary2']>['channels']

export const useImageChannelUpdateSubscription = (setId?: string) => {
  const viewerStore = useViewerStore()

  const uiStore = useUiStore()
  const route = useRoute()
  const currentSetId = computed<string | undefined>(() => route.params.id?.toString())
  const showMessage = (setId: string) => {
    if (currentSetId.value === setId) {
      uiStore.addToast({
        message: 'New channels is available for image set',
        type: 'info',
      })
    }
  }

  const mergeChannels = (oldChannels: Channels, newChannels: Channels): Channels => {
    return uniqBy([...(oldChannels || []), ...(newChannels || [])], 'channel_id')
  }

  const handleChannelUpdate: SubscriptionHandlerArg<OnImageChannelUpdate, void> = (_, response) => {
    const { onImageChannelUpdate } = response
    if (!onImageChannelUpdate) return

    // Process the channel update with new structure
    const { channels, set_id, type } = onImageChannelUpdate

    // Only update if the set_id matches (if provided) or if no specific setId filter
    if (setId && set_id !== setId) return

    // Update the viewer store with the new channels
    if (channels && channels.length > 0) {
      viewerStore.setSummary({
        ...viewerStore.summary,
        getImageSetSummary2: {
          ...viewerStore.summary?.getImageSetSummary2,
          channels: mergeChannels(
            viewerStore.summary?.getImageSetSummary2?.channels || [],
            channels,
          ),
        },
      })
    }

    showMessage(set_id)
  }

  const variables = computed(() => ({
    stream: wsStream.value ?? '',
    setId: setId,
  }))

  useOnImageChannelUpdate(
    {
      variables,
      pause: computed(() => !isUrqlClientReadySubscription.value || !wsStream.value),
    },
    handleChannelUpdate,
  )
}
