<template>
  <div class="input relative z-[1]" :class="modifiers">
    <label class="gray-800 mb-1 block text-base font-bold" v-if="label" :for="id">
      <template v-if="typeof label === 'string'">{{ label }}</template>
      <span v-if="optional" class="font-normal"> (optional) </span>
    </label>

    <!-- center -->
    <div class="relative flex flex-col">
      <div
        v-if="icon && iconPosition === 'left'"
        class="absolute left-3 top-[50%] z-[3] flex -translate-y-2/4 items-center pr-9 text-[0px]"
      >
        <SvgIcon :path="icon" />
      </div>
      <component
        :class="[
          'ui-input relative z-[2] block w-[100%] resize-none rounded-lg border border-gray-200 bg-white px-3 py-2 text-base transition',
          'placeholder:text-gray-500 hover:border-gray-800 focus:border-gray-800 disabled:pointer-events-none disabled:opacity-75',
          'dark:border-gray-700 dark:bg-gray-900',
          'dark:placeholder:text-gray-400 dark:hover:border-gray-500 dark:focus:border-gray-500',
          icon && iconPosition === 'left' && 'pl-8',
          inputClass,
        ]"
        :is="getElement"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        :type="type"
        :disabled="disabled"
        ref="inputRef"
        :style="textareaStyle"
        v-bind="$attrs"
        @input="setValue"
        @focus="handleFocus"
        @blur="handleBlur"
        :min="min"
        :max="max"
        :step="step"
      />

      <!-- show clear button -->
      <button
        v-if="value"
        @click="handleClear"
        class="absolute inset-y-0 right-0 z-[1001] flex cursor-pointer items-center pr-3 hover:text-gray-500"
      >
        <!-- Icon x (using SVG) -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <div
        class="absolute right-3 top-[50%] z-[3] flex -translate-y-2/4 items-center text-[0px]"
        ref="iconsRef"
      >
        <template v-if="loading || valid">
          <i class="input__icon animate-spin text-gray-500" v-if="loading">
            <SvgIcon :path="mdiLoading" />
          </i>
          <i class="input__icon text-green-700" v-else-if="valid && !disabled">
            <SvgIcon :path="mdiCheckBold" />
          </i>
        </template>

        <i
          class="input__icon text-red-700"
          :class="[isClearable && '_clearable']"
          v-if="showError && !showErrorText"
          @click="() => isClearable && clearInput()"
        >
          <SvgIcon :path="mdiCloseCircleOutline" />
        </i>

        <SvgIcon v-if="icon && iconPosition === 'right'" :path="icon" />
      </div>
    </div>

    <!-- bottom section -->
    <div
      class="mt-1 text-sm text-gray-500"
      v-if="maxCount"
      :class="[value.length > maxCount && 'text-red-700']"
    >
      {{ value.length }}/{{ maxCount }}
    </div>
    <div class="mt-1 text-sm text-red-700" v-if="showErrorText">{{ error }}</div>
    <div class="mt-1 text-sm text-gray-500" v-if="helperText">{{ helperText }}</div>
  </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { mdiLoading } from '@mdi/js'
import { mdiCheckBold, mdiCloseCircleOutline } from '@mdi/js'

const id = nanoid()
const inputRef = ref<HTMLInputElement | null>(null)

const emit = defineEmits(['onChange', 'onFocus', 'onBlur', 'onClear'])

const props = withDefaults(
  defineProps<{
    value: string | number
    label?: string | boolean
    type?: string
    placeholder?: string
    name?: string
    optional?: boolean
    theme?: 'primary' | 'secondary'
    icon?: string
    iconPosition?: 'left' | 'right'
    helperText?: string
    // modifiers
    error?: string | boolean | null
    valid?: boolean
    disabled?: boolean
    clearable?: boolean
    loading?: boolean
    // functional
    focusOnMount?: boolean
    focusHook?: boolean
    maxCount?: number
    // textarea
    maxRows?: number
    // others
    customClass?: string
    customStyle?: string
    min?: number
    max?: number
    step?: number
  }>(),
  {
    theme: 'primary',
    iconPosition: 'right',
  },
)

const modifiers = computed(() => [
  isFocusedOrNotBlank.value && '_focused',
  focusedState.value === id && 'z-[5]',
  showError.value && '_error',
  props.valid && !props.disabled && '_valid',
  props.icon && `_ipos-${props.iconPosition}`,
  `_${props.theme}`,
])

const inputClass = computed(() => [
  props.disabled && 'bg-gray-100 dark:bg-gray-950 dark:text-gray-400',
  props.customClass,
])

// general logic
const isTextarea = computed(() => {
  return props.type === 'textarea'
})

const getElement = computed(() => {
  return isTextarea.value ? 'textarea' : 'input'
})

const showError = computed(() => {
  return props.error
  // && !isFocused.value
})

const setValue = (e: Event) => {
  const target = e.target as HTMLInputElement
  const value = target.value

  emit('onChange', value, e)

  if (isTextarea.value) {
    nextTick(() => {
      resizeTextarea()
    })
  }
}

// focus handle
const isFocused = ref(false)
const [focusedState, setFocusedState] = useState(false)

const isFocusedOrNotBlank = computed(() => {
  try {
    if (props.value && props.value.toString().trim().length > 0) {
      return true
    }
  } catch {
    return false
  }

  return isFocused.value
})

const handleClear = () => {
  emit('onClear')
}

const handleFocus = () => {
  isFocused.value = true
  setFocusedState(id)
  emit('onFocus')
}
const handleBlur = () => {
  setTimeout(() => {
    isFocused.value = false
  })
  emit('onBlur')
}

const handleAutocompleate = (e: Event) => {
  const target = e.target as HTMLInputElement

  if (target && target.getAttribute('id') === id) {
    if (target.hasAttribute('autocompleted')) {
      isFocused.value = true
    }
  }
}

watch(
  () => props.focusHook,
  (newVal) => {
    if (newVal) {
      inputRef.value?.focus()
    }
  },
)

// inputs width watcher
const iconsRef = ref<HTMLElement | null>(null)
const placeholderRef = ref<HTMLElement | null>(null)

const setInputOffsets = () => {
  try {
    if (!iconsRef.value) return

    const iconsWidth = iconsRef.value?.offsetWidth + 5 + 16
    if (inputRef.value) inputRef.value.style.paddingRight = `${iconsWidth}px`
    if (placeholderRef.value) placeholderRef.value.style.right = `${iconsWidth}px`
  } catch {
    // unknown
  }
}

watch(
  () => [props.value, props.error, props.valid],
  () => {
    nextTick(setInputOffsets)
  },
)

// error
const showErrorText = computed(() => {
  // if (isFocused.value) return false
  return typeof props.error === 'string'
})

// clear
const isClearable = computed(() => {
  if (props.clearable) {
    return props.value && props.value.replace(/^\s+|\s+$/g, '').length > 1
  } else {
    return false
  }
})

const clearInput = () => {
  emit('onChange', '')
}

// textarea autosize
const height = ref('')
const resizeTextarea = () => {
  height.value = 'auto !important'
  nextTick(() => {
    if (!inputRef.value || !props.maxRows) return
    const style = window.getComputedStyle(inputRef.value)
    const padding = parseInt(style.paddingTop) + parseInt(style.paddingBottom)
    const lineHeight = parseInt(style.lineHeight)
    const maxHeight = lineHeight * props.maxRows + padding + 2

    let newHeight = inputRef.value.scrollHeight + 2
    if (newHeight >= maxHeight) newHeight = maxHeight

    height.value = `${newHeight}px`
  })
}

const textareaStyle = computed(() => {
  if (isTextarea.value) {
    return { height: height.value }
  } else {
    return {}
  }
})

// hooks
onMounted(() => {
  if (props.focusOnMount && !isAndroid()) {
    inputRef.value?.focus()
  }
  if (isTextarea.value) {
    nextTick(() => resizeTextarea)
  }

  document.addEventListener('onautocomplete', handleAutocompleate, false)
})

onBeforeUnmount(() => {
  setInputOffsets()
  document.removeEventListener('onautocomplete', handleAutocompleate, false)
})
</script>

<style lang="scss" scoped>
.input {
  &__icon {
    @apply mr-2 text-gray-500 transition last:mr-0;
    &._error {
      @apply text-red-700;
      &._clearable {
        @apply cursor-pointer;
        @apply hover:text-gray-800;
      }
    }

    &._clear,
    &._close {
      @apply cursor-pointer text-gray-500;
      @apply hover:text-red-700;
    }
  }

  &._valid {
    .input {
      &__input {
        @apply border-green-700;
      }
    }
  }

  &._error {
    .input {
      &__input {
        @apply border-red-700;
      }
    }
  }
}

@keyframes rotateLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>
