import { OnImageJobUpdate } from '@/graphql/generated'
import { SubscriptionHandlerArg } from '@urql/vue'
import { cloneDeep, findIndex } from 'lodash'

export const useGallerySubscriptionJobUpdate = (): void => {
  const jobStore = useImageJobStore()
  const { jobs } = storeToRefs(jobStore)

  const handleJobUpdate: SubscriptionHandlerArg<OnImageJobUpdate, void> = (_, response) => {
    const { onImageJobUpdate } = response
    if (!onImageJobUpdate) return

    const jobIndex = findIndex(jobs.value, (job) => job.set_id === onImageJobUpdate.set_id)
    const existingJob = jobs.value[jobIndex]

    if (existingJob?.state === 'error') return

    const jobsClone = cloneDeep(jobs.value)

    if (jobIndex === -1) {
      jobsClone.unshift({ ...onImageJobUpdate })
    } else {
      jobsClone[jobIndex] = { ...jobsClone[jobIndex], ...onImageJobUpdate }
    }

    jobStore.setJobs(jobsClone)
  }
  const variables = computed(() => ({ stream: wsStream.value ?? '' }))
  useOnImageJobUpdate(
    {
      variables,
      pause: computed(() => !isUrqlClientReadySubscription.value || !wsStream.value),
    },
    handleJobUpdate,
  )
}
