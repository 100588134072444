import { GetImageSetSummary2 } from '@/graphql/generated'
import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  IGridLineDto,
  IGridResponseDto,
  IOverlayColorName,
  IOverlayColors,
  IOverlayDto,
} from '../interface'

export const useViewerStore = defineStore('viewer', {
  state: () => {
    // NOTE: START DATA FROM API
    const summary = ref<GetImageSetSummary2>()
    const setSummary = (val: GetImageSetSummary2 | undefined) => (summary.value = val)

    const projection = computed(() => summary.value?.getImageSetSummary2?.projection)

    // stars & deep skies object
    const overlayObjects = ref<IOverlayDto[]>([])
    const setOverlayObjects = (val: IOverlayDto[]) => (overlayObjects.value = val)

    const visibleOverlayObjects = ref<IOverlayDto[]>([...overlayObjects.value])
    const setVisibleOverlayObjects = (values: IOverlayDto[] | null) => {
      if (values) {
        visibleOverlayObjects.value = [...values]
      } else {
        if (overlayTurnedOff.value) {
          visibleOverlayObjects.value = [...overlayObjects.value]
        } else {
          visibleOverlayObjects.value = []
        }
      }
    }

    const overlayTurnedOff = computed(
      () => overlayObjects.value.length > visibleOverlayObjects.value.length,
    )

    const getOverlayObjects = async (overlayPath: string) => {
      const overlayRes = await fetch(overlayPath).then((r) => r.json())
      setOverlayObjects(overlayRes)
      setVisibleOverlayObjects(overlayRes)
    }

    const gridData = ref<Record<number, IGridLineDto[]>>()
    const setGrid = (val: IGridResponseDto) => (gridData.value = val.grid)

    const getGrid = async (gridPath: string) => {
      const gridRes = await fetch(gridPath).then((r) => r.json())
      setGrid(gridRes)
    }
    // NOTE: END DATA FROM API

    const colors = ref<IOverlayColors>({
      plum: '#D86ECC',
      white: '#FFFFFF',
      darkTeal: '#46B1E1',
      lightYellow: '#F9F983',
      red: '#CC0000',
      black: '#000000',
      blue: '#0000FF',
    })

    const overlayColors = ref<IOverlayColorName[]>(['plum', 'darkTeal', 'lightYellow', 'white'])

    const overlayColor = ref<string>(colors.value.white)

    const setOverlayColor = (color: IOverlayColorName) => {
      overlayColor.value = colors.value[color]
      Colors.value = {
        galaxies: overlayColor.value,
        star: overlayColor.value,
        doubleStar: overlayColor.value,
        overlayLabelRect: colors.value.black,
        globularClustersLine: overlayColor.value,
        gridLabel: overlayColor.value,
        grid: colors.value.blue,
        quasars: overlayColor.value,
        darkNebulae: overlayColor.value,
        globularClusters: overlayColor.value,
        contour: overlayColor.value,
        brightNebulae: overlayColor.value,
        planetaryNebulae: overlayColor.value,
        openClusters: overlayColor.value,
      }
    }

    const Colors = ref({
      galaxies: overlayColor.value,
      star: overlayColor.value,
      doubleStar: overlayColor.value,
      overlayLabelRect: colors.value.black,
      globularClustersLine: overlayColor.value,
      gridLabel: overlayColor.value,
      grid: colors.value.blue,
      quasars: overlayColor.value,
      darkNebulae: overlayColor.value,
      globularClusters: overlayColor.value,
      contour: overlayColor.value,
      brightNebulae: overlayColor.value,
      planetaryNebulae: overlayColor.value,
      openClusters: overlayColor.value,
    })

    return {
      summary: computed(() => summary.value),
      setSummary,
      overlayObjects: computed(() => overlayObjects.value),
      setOverlayObjects,
      getOverlayObjects,
      projection,
      visibleOverlayObjects,
      setVisibleOverlayObjects,
      overlayTurnedOff,
      colors,
      Colors,
      overlayColors,
      overlayColor,
      setOverlayColor,
      getGrid,
      gridData,
    }
  },
  persist: [
    {
      paths: ['overlayColor', 'Colors'],
      storage: localStorage,
    },
  ],
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useViewerStore, import.meta.hot))
}
